import request from '@/utils/request'
import {objectToQueryString} from '@/utils/utils';

//路内管理-停车订单-查询订单列表
export function getParkingOrderByPage(params) {
    return request.post('/parkingLotInfo/order?' + objectToQueryString(params));
}

//修改车牌号
export function updateVehicleNumPlate(parkingOrderIntoId,parkingSpaceId, numPlate, color, reason) {
    return request.post('/common/changeNumPlate?parkingOrderIntoId=' + parkingOrderIntoId + '&parkingSpaceId='+parkingSpaceId+'&plateNumber=' + numPlate + '&plateColor=' + color + '&reason=' + reason)
}

//查询订单详情
export function searchParkingOrderDetail(parkingOrderIntoId) {
    return request.post('/parkingLotInfo/searchParkingOrderDetail?parkingOrderIntoId=' + parkingOrderIntoId)
}

//完结
export function addParkingOrderExit(parkingOrderIntoId, finishDate, isCalculFee) {
    return request.post('/common/confirmOut?isOut=0&parkingOrderIntoId=' + parkingOrderIntoId + '&finishDate=' + finishDate+'&isCalculFee='+isCalculFee)
}

//获取驶出信息
export function getParkingOrderExit(parkingOrderExitId) {
    return request.get('/ParkingManagermentController/getParkingOrderExit?parkingOrderExitId=' + parkingOrderExitId)
}

//获取驶入信息
export function getParkingOrderInto(parkingOrderInfoId) {
    return request.get('/ParkingManagermentController/getParkingOrderInto?parkingOrderInfoId=' + parkingOrderInfoId)
}

//获取停车场信息
export function findParkingLot(ParkingLotId) {
    return request.get('/ParkingManagermentController/findParkingLot?ParkingLotId=' + ParkingLotId)
}

//获取计费规则信息
export function getCalcDetailsByParkId(ParkingLotId) {
    return request.get('/ParkingManagermentController/getCalcDetailsByParkId?ParkingLotId=' + ParkingLotId)
}

//获取预约订单
export function getReservationOrder(params) {
    return request.get('/reservedController/getReservationOrder?' + objectToQueryString(params))
}

export function getOssImgs(cdnId) {
    // cdnId = '1695244406066479104'
    return request({
        method: 'get',
        url: '/getossimgs?cdnId=' + cdnId + '&psid=',
        headers: {'Content-Type': 'application/json'}
    })
    // return request.get('/ParkingManagermentController/getEsParkingOrderRecord?companyName=&parkingLotInfoId=&OrderCode=&parkingOrderType=&orderStatus=&payStatus=&plateNumberState=&VehicleId=&parkingType=1&phoneNumber=&parkingSpeaceCode=&parkingGoType=1&parkingTimeType=1&timeValues=null&beginTime=&endTime=&isExport=0&exportSize=1&pageNo=1&pageSize=10')
}


//抓拍
export function getPhotoGraph(params) {
    return request.post('/parkingLotInfo/photoGraph?' + objectToQueryString(params))
}