<template>
  <div class="page">
    <div class="search-box section">
      <el-form ref="query" :inline="true" :model="query" size="medium">
        <el-form-item prop="regionId">
          <el-cascader v-model="defaultRegion" :clearable="true" :filterable="true" :props="props"
                       :show-all-levels="false" placeholder="请选择区域"/>
        </el-form-item>
        <el-form-item prop="parkingLotId">
          <SuggestParking
              v-model="query.parkingLotId"
              :motionType="1"
              style="width: 260px"
          />
        </el-form-item>
        <el-form-item prop="vehicleId">
          <NumplateSuggest v-model="query.vehicleId" style="width: 260px"/>
        </el-form-item>
        <el-form-item prop="orderCode">
          <el-input
              v-model="query.orderCode"
              clearable
              placeholder="停车订单编号"
              prefix-icon=""
              style="width: 260px"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item prop="phoneNum">
          <el-input
            v-model="query.phoneNum"
            placeholder="手机号码"
            prefix-icon=""
            style="width: 260px"
            clearable
          ></el-input>
        </el-form-item> -->
        <el-form-item prop="reservationStatus">
          <el-select
              v-model="query.reservationStatus"
              clearable
              placeholder="请选择-预约状态"
              style="width: 260px"
          >
            <el-option label="已预约" value="0"></el-option>
            <el-option label="已到场" value="1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="searchDate">
          <el-date-picker
              v-model="query.searchDate"
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              style="width: 460px"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetQueryForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          共<span class="total-size">{{ pagination.totalSize }}</span
        >条信息
        </div>
        <div class="op-extra">
          <icon-button
              v-if="hasRights('002003001')"
              icon="share"
              title="导出"
              @click.native="exportData"
          />
        </div>
      </div>
      <el-table
          v-loading="isLoading"
          :data="dataList"
          border
          class="common-table"
          size="medium"
          style="width: 100%"
      >
        <el-table-column
            align="center"
            label="预约编号"
            prop="reservationCode"
            width="240"
        >
        </el-table-column>
        <el-table-column
            align="center"
            label="预约车场"
            prop="parkingLotName"
            width="240"
        >
        </el-table-column>
        <el-table-column align="center" label="车牌号码" width="240">
          <template slot-scope="scope">
            <Numplate :type="scope.row.plateColor">{{
                scope.row.numberPlate
              }}
            </Numplate>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="手机号"
            prop="phoneNum"
            width="240"
        >
        </el-table-column>
        <el-table-column
            align="center"
            label="订单编号"
            prop="orderCode"
            width="240"
        >
          <template slot-scope="scope">
            <el-button
                size="small"
                style="font-size: 14px"
                type="text"
                @click="goDetail(scope.row.parkingOrder)"
            >{{ scope.row.orderCode }}
            </el-button
            >
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="完结时间"
            prop="completeDate"
            width="240"
        >
        </el-table-column>
        <el-table-column
            align="center"
            label="预约时间"
            prop="reservationDate"
            width="240"
        >
        </el-table-column>
        <el-table-column align="center" label="到场时间" prop="" width="240">
        </el-table-column>
        <el-table-column
            align="center"
            label="预约状态"
            prop="reservationStatus"
            width="240"
        >
          <template slot-scope="scope">
            {{ scope.row.reservationStatus === "0" ? "已预约" : "已到场" }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="城区" prop="region" width="240">
          <!-- <template>
            山东省青岛市即墨区
          </template> -->
        </el-table-column>
        <el-table-column
            align="center"
            label="泊位编号"
            prop="parkingSpace"
            width="240"
        >
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getRegionList} from "@/api/user";
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
import NumplateSuggest from "@/components/NumplateSuggest";
import SuggestParking from "@/components/SuggestParking";
import Numplate from "@/components/Numplate";
import {getReservationOrder} from "@/api/parkingOrder";
import {doExport} from "@/api/common";

export default {
  name: "appointment_order",
  mixins: [BaseMixin, Permission],
  data() {
    return {
      defaultRegion: [],
      props: {
        lazy: true,
        async lazyLoad(node, resolve) {
          const {level, value} = node;
          console.log('node.value:', value)
          let regionId = level === 0 ? 0 : value;
          getRegionList({regionId: regionId})
              .then(({returnObject}) => {
                console.log('getRegionList:', returnObject)
                const nodes = returnObject.map(item => ({
                      value: item.id,
                      label: item.name,
                      leaf: item.level == 3,
                    })
                )
                resolve(nodes)
              })
        }
      },
      query: {
        regionId: "", //城区
        parkingLotId: "", //停车场名称
        vehicleId: "", //车辆id
        orderCode: "", //订单编号
        phoneNum: "", //手机号
        reservationStatus: "", //预约状态   1待入场  2待入位  3已完成  3已取消
        startingDate: "", //开始时间
        endDate: "", //结束时间
        searchDate: [],
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      dataList: [],
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
    };
  },
  components: {
    NumplateSuggest,
    SuggestParking,
    Numplate,
  },
  methods: {
    //重置查询条件
    resetQueryForm() {
      this.$refs.query.resetFields();
      this.doSearch();
    },
    //查询
    doSearch() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.search();
    },
    async search() {
      this.isLoading = true;
      this.query.region = this.defaultRegion.length == 3 ? this.defaultRegion[2] : this.defaultRegion[1]
      const params = this.paramFormat(this.query);
      if (this.query.searchDate.length > 0) {
        params["startingDate"] = this.searchDate[0];
        params["endDate"] = this.searchDate[1];
      }
      const res = await getReservationOrder(params);
      this.isLoading = false;
      if (res && res.code === 30 && res.result) {
        const returnObject = res.returnObject;
        this.dataList = returnObject.list;
        this.pagination.totalSize = returnObject.count;
        this.pagination.totalPages = returnObject.totalPage;
        this.form.exportSize = returnObject.count;
      }
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      if (this.query.searchDate.length > 0) {
        params["startingDate"] = this.searchDate[0];
        params["endDate"] = this.searchDate[1];
      }
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      doExport(
          "/reservedController/getReservationOrder",
          params,
          "预约订单.xlsx"
      );
      loading.close();
      this.exportDialogFormVisible = false;
    },
    //查看预约订单详情
    goDetail(item) {
      sessionStorage.setItem("order_detail", JSON.stringify(item));
      this.$router.push("/share/parking_order_detail");
    },
  },
};
</script>

<style lang="less" scoped>
</style>
