<template>
  <div>
    <el-autocomplete
        v-model="selectVal"
        :disabled="disabled"
        :fetch-suggestions="querySearch"
        :placeholder="placeholder"
        :trigger-on-focus="false"
        class="inline-input"
        clearable
        style="width: 100%"
        value-key="plateNumber"
        @blur="onBlur"
        @clear="clearVal"
        @select="handleSelect"
    >
      <i slot="suffix" class="el-icon-edit el-input__icon"> </i>
      <template slot-scope="scope">
        <div class="autocomplete-item">
          <span>{{ scope.item.plateNumber }}</span>
          <span :class="getAutoType(scope.item.autoType)" class="autoType">{{
              autoTypeConfig[scope.item.autoType]
            }}</span>
        </div>
      </template>
    </el-autocomplete>
  </div>
</template>

<script>
import {getNumplateListByKeyword} from "@/api/common";

export default {
  name: "suggest-numplate",
  props: {
    value: String,
    placeholder: {
      type: String,
      default: "搜索车牌号码（最小4个字符）",
    },
    disabled: Boolean,
  },
  data() {
    return {
      parkingList: [],
      selectVal: "",
      isChange: false,
      autoTypeConfig: {
        "01": "黄牌",
        "02": "蓝牌",
        "03": "新能源",
        "04": "黄绿牌",
        "06": "黑牌",
        "23": "白牌",
        "99": "其他",
      },
    };
  },
  watch: {
    value(newVal) {
      if (!newVal || newVal === "") {
        this.selectVal = "";
      }
    },
  },
  methods: {
    getAutoType(autoType) {
      return "type_" + autoType;
    },
    async onBlur() {
      setTimeout(() => {
        if (!this.isChange) {
          this.selectVal = "";
        }
      }, 200);
    },
    async querySearch(queryString, cb) {
      if (queryString.length <= 3) {
        cb([]);
        return;
      }

      const res = await getNumplateListByKeyword(queryString);

      if (res && res.code === 30 && res.result) {
        cb(res.returnObject.autoResultList);
      } else {
        cb([]);
      }
    },
    handleSelect(e) {
      // this.$emit("input", e.id);
      this.$emit("input", e.plateNumber);
      this.isChange = true;
    },
    clearVal() {
      this.$emit("input", "");
    }
  },
};
</script>

<style lang="less" scoped>
.autocomplete-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .autoType {
    font-size: 12px;
    display: inline-block;
    height: 20px;
    padding: 0 3px;
    line-height: 20px;
    color: #fff;
    border-radius: 3px;

    &.type_02 {
      background: #0b6fc0;
    }

    &.type_01 {
      background: #e6b201;
    }

    &.type_03 {
      background: #0bc029;
    }

    &.type_04 {
      background: linear-gradient(90deg, #e6b201 42.8%, green 0, green);
    }

    &.type_06 {
      background: #333;
    }

    &.type_23 {
      background: #fff;
      color: #333;
    }

    &.type_99 {
      background: #0b6fc0;
    }
  }
}
</style>
